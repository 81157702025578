<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'
import { cn } from '@/modules/ui/lib/utils'
import { Primitive, type PrimitiveProps } from 'radix-vue'

const props = withDefaults(defineProps<PrimitiveProps & {
  class?: HTMLAttributes['class']
  href?: string
}>(), {
  as: 'a',
})

const isExternalUrl = computed(() => {
  if (!props.href) {
    return false
  }
  return /^[a-z]+:\/\/|^mailto:|^tel:/i.test(props.href)
})

// If href is provided and we're not already using NuxtLink (asChild),
// we should render as NuxtLink for internal links, or anchor for external
const componentAs = computed(() => {
  if (props.href && !props.asChild) {
    return isExternalUrl.value
      ? 'a'
      : defineNuxtLink({
          componentName: 'NuxtLink',
        })
  }
  return props.as
})
</script>

<template>
  <Primitive
    :as="componentAs"
    :as-child="asChild"
    :class="cn('transition-colors hover:text-foreground', props.class)"
    :to="!isExternalUrl ? href : undefined"
    :href="isExternalUrl ? href : undefined"
  >
    <slot />
  </Primitive>
</template>
